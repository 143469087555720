<template>
    <Transition enter-active-class="show-right-full" leave-active-class="show-right-full-out">
        <nav
            class="fixed inset-0 z-10000 w-full h-screen overflow-hidden bg-bw-2 pointer-events-auto bg-final-01 lg:hidden max-w-screen flex flex-col bg-white"
            v-if="isOpen"
        >
            <div
                class="container flex items-center justify-between h-18 bg-white b-b-1 b-solid b-bw-08 sticky top-0 z-1000"
            >
                <nuxt-link class="w-30 h-10" to="/">
                    <img src="/images/global/logo.png" alt="" class="w-full h-full object-contain" />
                </nuxt-link>

                <div class="cursor-pointer flex items-center gap-4">
                    <div
                        class="flex-none text-bw-15 w-10 h-10 rounded-full flex flex-col items-center justify-center"
                        @click="$emit('changeOpenNav', false)"
                    >
                        <span class="i-custom-close text-xl"></span>
                    </div>
                </div>
            </div>
            <div class="container py-2 bg-gray-200">
                <form
                    action="/tim-kiem"
                    class="relative bg-white flex items-center justify-between"
                    @submit.prevent="handleSearch"
                >
                    <input type="text" v-model="textSearch" placeholder="Search" class="w-full p-2" name="text" />
                    <button class="absolute right-2 w-5 h-5 col-flex">
                        <span class="i-ic:baseline-search text-xl text-blacks-100"></span>
                    </button>
                </form>
            </div>
            <div
                class="w-full bg-white z-20 fixed top-full right-0 duration-250"
                :class="textSearch ? '!top-[128px]' : ''"
            >
                <div class="px-6 w-full h-screen overflow-y-scroll">
                    <div class="flex gap-6 flex-col py-4">
                        <nuxt-link
                            :to="item?.url"
                            v-for="item in dataSearch?.products"
                            class="flex gap-4 group"
                            @click="$emit('changeOpenNav', false)"
                        >
                            <div class="w-[76px] h-[76px] overflow-hidden">
                                <NuxtImg
                                    :src="getThumbnail(item?.images[0], 200, true)"
                                    width="200"
                                    sizes="sm:100vw md:50vw lg:200px "
                                    loading="lazy"
                                    :alt="item?.title"
                                    :placeholder="[50, 25, 75, 5]"
                                    class="w-full h-full object-cover duration-250 transform group-hover:scale-115"
                                />
                            </div>
                            <div class="flex flex-col flex-1 gap-1">
                                <div class="text-blacks-100 text-sm font-medium line-clamp-2">{{ item?.title }}</div>
                                <div class="text-blacks-70 text-[12px]">{{ item?.sku }}</div>
                                <div class="flex gap-1 items-start">
                                    <div class="text-blacks-100 text-sm font-medium">{{ item?.price }}</div>
                                    <div class="text-blacks-100 text-[10px] leading-[1.5] font-medium uppercase">
                                        vnđ
                                    </div>
                                </div>
                            </div>
                        </nuxt-link>
                    </div>
                    <div
                        v-if="dataSearch?.products?.length === 0"
                        class="text-center text-lg text-blacks-100 font-medium"
                    >
                        Không tìm thấy sản phẩm phù hợp
                    </div>
                </div>
            </div>
            <div class="flex-1 flex flex-col h-auto mb-3 py-3 menu-mobile">
                <ul class="flex-1 relative divide-y">
                    <li v-for="(item, index) in dataRender?.menus" :key="item?.id" class="nav__item">
                        <MenuItemHasChild :item="item" />
                        <ul v-if="item?.mega" class="nav__sub divide-y">
                            <MenuBackItem />

                            <MenuItemMega
                                v-if="dataHasBrand && dataHasBrand.length > 0"
                                v-for="brand in dataHasBrand"
                                :title="brand?.title"
                                :dataRender="brand?.product_categories"
                            />
                            <MenuItemMega title="Bộ sưu tập" :dataRender="dataRender?.collections" isConvert />
                        </ul>
                    </li>
                </ul>
            </div>
        </nav>
    </Transition>
</template>

<script setup lang="ts">
import WrapperProduct from '../../views/collection/[slug]/components/WrapperProduct.vue'
const props = defineProps({
    dataRender: {
        type: Object as any
    },
    bannerNewCollection: {
        type: Object as any
    },
    isOpen: {
        type: Boolean
    },
    dataHasBrand: {
        type: Object as any
    }
})
const textSearch = ref('')
const dataSearch = ref()

const router = useRouter()
const { searchProducts } = useProducts()

const itemMenu = ref<HTMLElement>()
const getItem = (event) => {
    event.nextElementSibling?.classList.add('is-active')
}
const backItem = (event) => {
    event.parentNode.parentNode?.classList.remove('is-active')
}
const emits = defineEmits(['changeOpenNav'])
const itemBanner = computed(() => props?.bannerNewCollection?.banners?.banner_items[0])
const handleSearch = () => {
    emits('changeOpenNav', false)
    router.push({ name: 'search', query: { text: textSearch.value } })
}
watchDebounced(
    () => textSearch.value,
    async () => {
        const res = await searchProducts({}, { text: textSearch.value }, { limit: 9, page: 1 })
        dataSearch.value = res
    },
    { debounce: 500 }
)
</script>

<style>
.menu-mobile a,
.menu-mobile p {
    @apply px-15px;
}

.menu-mobile span.item-close {
    @apply mr-2;
}

.nav__sub {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s ease-in-out;
    transform: translateX(100%);
    @apply bg-white;
}

.nav__sub.is-active {
    opacity: 1;
    visibility: visible;
    transform: translateX(0%);
    @apply bg-white z-1000 divide-y;
}
</style>
